.Banner {
  visibility: hidden;
  color: white;
  font-weight: 600;
  padding: 20px 0;
  position: fixed;
  width: 100%;
  z-index: 1000;
  transition-property: top;
  transition: 300ms;
  top: -80px;
  color: rgba(0, 0, 0, 0);
}

.Message {
  background-color: #1E2B34;
}

.Active {
  visibility: visible;
  top: 0;
  color: white;
}

.Alert {
  background-color: #DF0941;
}

.Success {
  background-color: #14BE76;
}