.Dropdown-component {
  position: absolute;
  display: none;
  flex-direction: column;
  background-color: white;
  margin-top: 42px;
  border-radius: 3px;
  padding: 12px 12px;
  min-width: 100px;
  text-align: left;
}

.Dropdown-list {
  display: flex;
  flex-direction: column;
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.Show {
  display: flex;
}

.Dropdown-link {
  text-decoration: none;
  font-size: 15px;
  color: black;
  border-radius: 3px;
  transition: 300ms;
}

.Dropdown-item-text {
  padding: 8px 0;
}

.Dropdown-link:hover {
  background-color: #F2F5FC;
  color: #0093FF;
}