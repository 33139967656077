.Rules-heading {
  color: #3E4D57;
  text-transform: uppercase;
}

.Rules {
  display: none;
}

.Rules-list {
  display: flex;
  flex-direction: column;
  align-items: center;
  list-style: none;
  padding: 0;
}

.Rule-item:last-child {
  margin-bottom: 24px;
}

.Rules-title {
  color: #0084E5;
  text-transform: uppercase;
  margin-bottom: 0;
}

.Rule-item {
  margin: 6px 0;
  font-size: 18px;
  font-weight: 600;
  text-align: left;
  color: #3E4D57;
  width: 475px;
}

.Rule-item h2 {
  margin-bottom: 8px;
}

.Rule-item p {
  margin: 0;
}

.Podcast-description {
  margin-bottom: 18px;
}

.Podcast-link {
  cursor: pointer;
  
}

.Podcast-img {
  height: 100%;
  width: 100%;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  height: 64px;
  border-radius: 3px;
  margin-top: 12px;
}

@media (max-width: 650px) {
  .Rules {
    display: block;
  }

  .Rules-title {
    display: none;
  }

  .Rules-list {
    margin: 0;
  }

  .Rule-item {
    font-size: 16px;
    max-width: 85%;
  }
}