.Comment-component {
    padding: 4px 24px;
    margin-top: 12px;
    margin-bottom: 4px;
}

.Comment-input {
    font-family: OpenSans;
    background-color: #F2F5FC;
    width: 100%;
    font-size: 14px;
    border: none;
    padding: 12px;
    height: 18px;
    border-radius: 3px;
}

.Comment-input:focus {
    outline: solid 2px #0093FF;
}

.Comment-submit {
    border-radius: 3px;
    height: 42px;
    min-width: 84px;
    width: 144px;
    margin-left: 12px;
    text-align: center;
    padding: 0 8px 0 8px;
}


@media (max-width: 650px) {
    .Comment-component {
        padding: 4px 18px;
    }

    .Comment-input {
        font-size: 16px;
    }
}

@media (max-width: 400px) {
    .Comment-input {
        background-color: white;
    }
}