header {
  position: fixed;
  right: 0;
  left: 0;
  display: flex;
  flex-direction: row;
  padding: 0 16px;
  transition: all 300ms;
}

.Header-container {
  margin: 0 auto;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.PL-logo {
  height: 36px;
  margin: 10px 0;
}

.Dropdown-menu {
  display: flex;
  flex-direction: row;
  width: 200px;
  justify-content: flex-end;
}

.Dropdown-link {
  display: flex;
  align-items: center;
  padding: 12px;
  color: #3E4D57;
  background-color: white;
  height: 12px;
  font-size: 14px;
  line-height: 14px;
  font-weight: 600;
  border-radius: 3px;
  cursor: pointer;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently supported by Chrome, Opera and Firefox */
}

.Tablet-menu {
  display: flex;
  align-items: center;
  padding: 12px;
  color: #3E4D57;
  background-color: white;
  height: 12px;
  font-size: 14px;
  line-height: 14px;
  font-weight: 600;
  border-radius: 3px;
  cursor: pointer;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently supported by Chrome, Opera and Firefox */
  display: none;
}

.Dropdown-item {
  margin-right: 4px;
}

@media (max-width: 1124px) {
  .Tablet-menu {
    display: flex;
  }
  
  .Dropdown-menu {
    display: none;
  }
}

@media (max-width: 650px) {
  header {
    margin-top: -75px;
    visibility: none;
    /* display: none; */
  }
}