.Footer {
    display: flex;
    flex-direction: row;
    justify-content: center;
    height: 100%;
}

.Footer-content {
    display: flex;
    flex-direction: row;
    background-color: #162026;
    border-radius: 100px;
    margin: 12px;
    padding: 4px;
    justify-content: center;
    align-items: center;
}

.Footer-item {
    margin: 0 8px;
    color: #F2F5FC;
    font-size: 14px;
    line-height: 14px;
}

.LM-site-link {
    color: #0093FF;
    padding: 4px 14px;
    border-radius: 100px;
    text-decoration: none;
    font-size: 14px;
    font-weight: 600;
    transition: 300ms;
}

.LM-site-link:hover {
    background-color: #0093FF;
    color: white;
}

.LM-site-link > p {
    margin: 0;    
}

@media (max-width: 400px) {
    .Footer-content {
        display: none;
    }
}