.Task-history {
  padding: 0 24px;
}

.Task-summary {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  border-bottom: 1px solid #F2F5FC;
}

.Summary-stats {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.Summary-item {
  font-weight: bold;
  color: #636F77;
}

.Date-outcome {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background-color: #636F77;
  color: white;
  font-weight: bold;
  font-size: 14px;
  width: 80px;
  height: 24px;
  margin-left: 14px;
  border-radius: 12px;
}

.Win {
  background-color: #0093FF;
}

@media (max-width: 650px) {
  .Task-history {
    background-color: white;
    padding: 12px 18px;
    border-radius: 3px;
    box-shadow: 0 2px 14px 0 rgba(0,0,0,0.05);
  }
}