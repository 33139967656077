.Comment-list {
    background-color: #F2F5FC;
    padding: 8px 24px;
    display: flex;
    flex-direction: column-reverse;
    /* align-items: flex-end; */
    /* flex-wrap: wrap; */
    height: 50vh;
    overflow: hidden;
    overflow-y: scroll;
    border-top: solid 2px #F2F5FC;
    border-bottom: solid 2px #F2F5FC;
  }

  .Comment.Empty {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0);
  }

  .Empty-comment-icon {
    height: 44px;
    width: 44px;
  }
  
  #Empty-comment-icon-wrapper {
    fill: #9FB0BB;
  }

  #Empty-comment-message {
    color: #9FB0BB;
    font-size: 18px;
    font-weight: 600;
    margin: 12px 0;
  }

  .Comment {
    background-color: white;
    margin-bottom: 12px;
    padding: 18px 18px 0px 18px;
    /* width: 100%; */
  }
  
  .Comment > p {
    margin: 0;
  }
  
  .Comment-footer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 12px 0 18px 0;
  }

  .Comment-footer .Icon-wrapper {
    margin-top: 0px;
  }
  
  .Comment-footer > p {
    font-size: 13px;
    font-weight: 600;
    letter-spacing: -0.25px;
    color: #8C8C8C;
    margin: 0;
  }
  
  .Comment-icons {
    display: flex;
    flex-direction: row;
  }

  @media(max-width: 650px) {
    .Comment-list {
      height: 100%;
      padding: 0 18px;
    }
  }

  @media(max-width: 400px) {
    .Comment-list {
      padding: 24px 18px;
    }
  }