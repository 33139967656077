.Back-btn {
    color: #3E4D57;
    font-weight: 600;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-top: 8px;
    text-decoration: none;
    transition: 300ms;
}

.Back-btn:hover {
    color: #0093FF;
}

.Back-arrow {
    margin-right: 8px;
}

@media (max-width: 650px) {
    .Profile-legend {
        display: none;
    }

    .Form-input {
        background-color: white;
    }
}