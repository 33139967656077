.Date-nav {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0 24px;
  margin-bottom: 12px;
}

.Next-date {
  display: inline-block;
  font-size: 14px;
  font-weight: bold;
}

.Current-date {
  color: #3E4D57;
  margin: 4px 0;
  transition: 300ms;
}

.Change-date {
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  transition: 300ms;
  color: #636F77;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently supported by Chrome, Opera and Firefox */
}

.Change-date:hover {
  color: #0093FF;
}


.Traverse-left {
  display: inline-block;
  margin-right: 8px;
}

.Traverse-right {
  display: inline-block;
  margin-left: 8px;
}

.Disabled {
  color: #E5E8EF;
  cursor: auto;
}

.Disabled:hover {
  color: #E5E8EF;
}

@media (max-width: 650px) {
  .Date-nav {
    margin-bottom: 4px;
  }
  .Next-date {
    color: white;
  }

  .Current-date {
    color: white;
  }

  .Change-date {
    color: white;
  }

  .Change-date:hover {
    color: white;
  }

  .Disabled {
    color: rgba(255, 255, 255, 0.2);
  }

  .Disabled > p {
    color: rgba(255, 255, 255, 0.2);
  }

}

@media (max-width: 450px) {
  .Next-date {
    display: none;
  }
  .Change-date {
    background-color: #0084E5;
    height: 24px;
    width: 24px;
    justify-content: center;
    border-radius: 50%;
  }
  .Traverse-left {
    margin-right: 0;
  }

  .Traverse-right {
    margin-left: 0;
  }
}

@media (max-width: 400px) {
  .Current-date {
    font-size: 24px;
  }
}