input[type="checkbox"] {
    display: none;
  }
  input[type="checkbox"] + span {
    display: inline-block;
    position: relative;
    top: -1px;
    width: 24px;
    height: 24px;
    margin: 18px 0px 0 0;
    vertical-align: middle;
    background: #E5E8EF left top no-repeat;
    cursor: pointer;
  }
  input[type="checkbox"]:checked + span {
    background: url('./check.svg') no-repeat center, #0093FF -19px top no-repeat;
    background-size: 18px, cover;
  }

  input[type="checkbox"]:disabled + span {
    background: #F2F5FC -19px top no-repeat;
    cursor: auto;
  }
  
  input[type="checkbox"] + span {
    margin-right: 12px;
    border-radius: 3px;
  }