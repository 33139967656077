.Stats {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  background-color: #F2F5FC;
  margin-bottom: 18px;
}

.Metric-container {
  width: 33%;
}

.Stat-title {
  color: #636F77;
  font-weight: bold;
}

.Stat {
  font-size: 44px;
  font-weight: bold;
  color: #0093FF;
  margin: 0 0;
  text-transform: uppercase;
}

.Stat-detail {
  font-size: 14px;
  font-weight: bold;
  color: #636F77;
}

.Default-outcome {
  color: #636F77;
}

@media (max-width: 650px) {
  .Stats {
    padding-top: 0;
    border-radius: 3px 3px 0 0;
  }

  .Stat-title {
    font-size: 0.75em;
    text-transform: uppercase;
  }

  .Stat {
    font-size: 2em;
  }
  .Stat-detail {
    font-size: 0.7em;
  }
}

@media (max-width: 400px) {
  .Stat-detail {
    display: none;
  }

  .Stat-title {
    font-size: 0.65em;
  }
}