.Task-list {
  padding: 0 24px;
  margin: 12px 0;
}

.List-item {
  text-align: left;
  width: 100%;
  border-bottom: 1px solid #f3f3f3;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
}

.Task-contents {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}

.Task {
  display: inline-block;
  transition: 300ms;
  color: #3E4D57;
  font-weight: 600;
  letter-spacing: -0.5px;
  max-width: 450px;
  margin: 17px 0;
}

.Complete {
  color: #9FB0BB;
  font-style: italic;
}

.Task.Disabled {
  color: #9FB0BB;
}

.Icons-container {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: stretch;
}

.Icon-wrapper {
  height: 100%;
  display: flex;
  align-items: center;
  margin-top: 20px;
  transition: 300ms;
  padding: 0 8px;
  color: #636F77;
}

.Icon-wrapper > p {
  margin: 0 0 0 2px;
}

.Icon-wrapper:hover {
  color: #0093FF;
  cursor: pointer;
}

.Comment-total {
  font-weight: bold;
  font-size: 13px;
  margin-left: 4px;
}

.Comment-icon {
  height: 16px;
}

#Comment-icon-wrapper {
  fill: #636F77;
  transition: 300ms;
}

.Icon-wrapper:hover #Comment-icon-wrapper {
  fill: #0093FF;
}

#Comment-icon-path {
  fill: red;
}


@media (max-width: 400px) {
  .Task-list {
    padding: 0;
    padding-top: 14px;
  }

  .List-item {
    flex-direction: column;
    border-bottom: none;
    margin-bottom: 10px;
    background-color: white;
    align-items: stretch;
    box-shadow: 0 2px 10px 0 rgba(0,0,0,0.05);
  }

  .Task-contents {
    padding: 8px 18px 0 18px;
  }

  .Icons-container {
    justify-content: space-between;
    padding: 0 18px;
  }

  .Icon-wrapper {
    padding: 8px 16px;
    margin: 8px 0;
  }
}