.Mobile-menu {
  position: fixed;
  top: 0;
  right: 0;
  z-index: 10000;
  height: 100%;
  width: 0%;
  background-color: rgba(30, 43, 52, 0.6);
  backdrop-filter: blur(4px);
  text-align: left;
  animation-name: 300ms slidein;
}

@keyframes slideinafter {
  from {
    opacity: 0;
    width: 0%;
  }
  to {
    width: 33%;
    opacity: 1;
  }
}

@keyframes slidein {
  from {
    width: 0%;
    opacity: 0%;
  }
  to {
    width: 100%;
    opacity: 100%;
  }
}

@keyframes showlinks {
  from {
    visibility: none;
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.Mobile-menu-content {
  width: 33%;
  position: fixed;
  right: 0;
  background-color: rgb(30, 43, 52);
  height: 100%;
  padding-top: 16px;
  animation-name: slideinafter;
  animation-duration: 300ms;
}

.Show-mobile-menu-bg {
  width: 100%;
}

.Mobile-pl-logo {
  color: #9FB0BB;
  text-align: left;
  font-size: 13px;
  font-weight: 600;
  width: 90%;
  margin: auto;
  padding: 8px;
  padding-left: 24px;
  margin-bottom: 12px;
}

.Mobile-logo-svg {
  height: 24px;
}

.Mobile-nav-list {
  list-style-type: none;
  padding: 0;
  width: 90%;
  margin: auto;
  font-size: 18px;
  font-weight: 600;
  text-transform: uppercase;
}

.Mobile-link {
  opacity: 0;
  padding: 12px;
  margin-bottom: 8px;
}

.current-mobile-link > span {
  color: #0084E5;
}

.current-mobile-link > i {
  color: #0084E5;
}

.current-mobile-link .Mobile-nav-logo .pl-icon-poly-mobile {
  fill: #0084E5;
}

.Show-mobile-link {
  opacity: 100;
  animation: 1500ms alternate showlinks;
}

.Mobile-nav-list a {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.Mobile-nav-logo {
  height: 18px;
  width: 18px;
  margin-right: 8px;
}

.pl-icon-poly-mobile {
  fill: white;
}

.Mobile-nav-icon {
  margin-right: 10px;
  font-size: 16px;
}

.Mobile-nav-list a {
  text-decoration: none;
  color: white;
}

.Mobile-logout {
  position: fixed;
  display: flex;
  flex-direction: row;
  align-items: center;
  bottom: 0;
  color: #9FB0BB;
  background-color: #19242C;
  font-weight: 600;
  padding: 12px;
  padding-left: 24px;
  width: 90%;
  text-transform: uppercase;
}

.Logout-icon {
  height: 16px;
  width: 16px;
  margin-right: 10px;
}

.No-display {
  display: none;
}


@media (max-width: 650px) {
  @keyframes slideinafter {
    from {
      opacity: 0;
      width: 0%;
    }
    to {
      width: 50%;
      opacity: 1;
    }
  }
  .Mobile-menu-content {
    width: 50%;
  }
}

@media (max-width: 400px) {
  @keyframes slideinafter {
    from {
      opacity: 0;
      width: 0%;
    }
    to {
      width: 70%;
      opacity: 1;
    }
  }
  .Mobile-menu-content {
    width: 70%;
  }
}