.App {
  text-align: center;
  min-height: 100vh;
  font-family: OpenSans, sans-serif;
  background-image: url('./assets/bg-img17.jpg');
  background-size: cover;
  background-position: top;
  background-repeat: no-repeat;
  background-attachment: fixed;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  touch-action: manipulation;
}

/****** LAYOUT ******/

.Content {
  display: grid;
  grid-template-columns: auto;
  margin-top: 65px;
  transition: all 300ms;
}

.Main {
  width: 100%;
  display: flex;
  padding-top: 16px;
  justify-content: center;
  transition: all 300ms;
}

.Container {
  display: flex;
  flex-direction: column;
  width: 650px;
  max-width: 98%;
  align-items: center;
}

.Page-title {
  font-size: 18px;
  font-weight: bold;
  text-transform: uppercase;
  color: #9FB0BB;
  margin: 0px;
}

.Card {
  background-color: #ffffff;
  padding: 18px 0;
  box-shadow: 0 2px 14px 0 rgba(0,0,0,0.05);
  margin-bottom: 24px;
  border-radius: 5px;
  width: 650px;
  max-width: 98%;
  transition: all 300ms;
}

button,
[type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button;
}

/****** FORM STYLES ******/

.Form-container {
  padding-top: 75px;
  margin: auto;
  width: 550px;
  max-width: 98%;
}

.Signup-login-logo {
  width: 250px;
  margin: 18px 0 8px 0;
}

.Profile {
  padding-top: 81px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.Profile-icon {
  color: #3E4D57;
}

fieldset {
  border: 0px solid white;
  text-align: left;
}

.Form-element {
  display: block;
  margin: 0 24px;
}

legend {
  text-align: center;
  font-size: 24px;
  font-weight: bold;
  text-transform: uppercase;
  color: #9FB0BB;
  margin-bottom: 14px;
}

.Form-label-wrapper {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
}

label {
  display: block;
  margin-bottom: 8px;
  font-size: 15px;
}

.Form-label-link {
  text-decoration: none;
  font-weight: 600;
  color: #0093FF;
  letter-spacing: -.5px;
  transition: 300ms;
}

.Form-label-link:hover {
  color: #0084E5;
}

.Form-input {
  height: 44px;
  width: 100%; 
  box-sizing: border-box;
  -webkit-box-sizing:border-box;
  -moz-box-sizing: border-box;
  margin-bottom: 18px;
  border: 0px solid white;
  background-color: #F2F5FC;
  padding: 0 14px;
  font-size: 16px;
}

.Form-input:focus {
  outline: 2px solid #0093FF;
}

input[type="submit"] {
  background-color: #0093FF;
  font-size: 14px;
  color: white;
  font-weight: bold;
  margin-top: 12px;
  margin-bottom: 0;
  cursor: pointer;
  border-radius: 3px;
  transition: 300ms;
}

input[type="submit"]:hover {
  background-color: #0084E5;
}

.Terms-text {
  text-align: center;
  color: #3E4D57;
}

#Signup-password {
  margin-bottom: 0;
}

#Signup-password + p {
  color: #9FB0BB;
  font-size: 14px;
  font-weight: 600;
  font-style: italic;
  margin: 2px;
}

.Form-element.PW {
  margin-bottom: 18px;
}

.Change-login-signup {
  display: flex;
  justify-content: center;
  padding: 8px 0;
  margin: 0 24px;
}

.Confirmation p {
  font-weight: normal;
}

.Signup-link {
  background-color: #F2F5FC;
  margin-top: 6px;
  border-radius: 3px;
  width: 100%;
  padding: 12px;
  display: flex;
  justify-content: center;
  font-size: 14px;
}

.Change-form-text {
  font-weight: 600;
  color: #9FB0BB;
  margin: 0 8px 0 0;
}

.Form-link {
  text-decoration: none;
  font-weight: bold;
  color: #0093FF;
  display: flex;
  flex-direction: row;
  align-items: center;
  transition: 300ms;
}

.Form-link > i {
  margin-right: 8px;
}

.Form-link > p {
  margin: 0;
}

.legal {
  display: inline-block;
  margin: 0 6px;
}

.Form-link:hover {
  color: #0084E5;
}

.Delete-account {
  display: flex;
  flex-direction: column;
  color: #DF0941;
  font-weight: 600;
  background-color: white;
  padding: 0px 22px;
  border-radius: 3px;
  box-shadow: 0 2px 14px 0 rgba(0,0,0,0.05);
  transition: 300ms;
  cursor: pointer;
  margin-bottom: 24px;
}

.Delete-account:hover {
  background-color: #DF0941;
  color: white;
}


/***** COMMENTS MODAL *****/

.ReactModal__Body--open {
  overflow: hidden;
  transition: 300ms;
}

.ReactModal__Overlay {
  opacity: 0;
  /* transition: 300ms ease-in-out; */
}

.ReactModal__Overlay--after-open {
  opacity: 1;
}

.ReactModal__Overlay--before-close {
  opacity: 0;
}

.Modal-overlay {
  position: fixed;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  background-color: rgba(1, 183, 255, 0.25);
  backdrop-filter: blur(22px);
  transition: 300ms ease-in-out;
}

.Comment-modal {
  background-color: white;
  width: 600px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: auto;
  margin-top: 64px;
  box-shadow: 0 2px 14px 0 rgba(0,0,0,0.05);
  border-radius: 5px;
}

.Comment-modal:focus {
  outline: none;
}

.Task-title {
  background-color: #0084E5;
  color: white;
  padding: 18px 24px 24px 32px;
  border-radius: 3px 3px 0px 0px;
  display: flex;
  justify-content: space-between;
  letter-spacing: -1px;
  align-items: center;
}

.Task-title > h1 {
  margin: 8px 0 0 0;
  font-weight: normal;
}

.Comment-close-icon {
  font-size: 18px;
  padding: 8px;
  color: white;
  cursor: pointer;
  transition: 300ms;
  color: rgba(0, 0, 0, 0.40);
}

.Comment-close-icon:hover {
  color: rgba(0, 0, 0, 0.30);
}

.Comment-submit-instruction {
  color: #9FB0BB;
  font-size: 13px;
  font-weight: 600;
  margin: 0 24px 8px 24px;
}

/* Sign Up Error Notification */

.Signup-error-banner {
  color: white;
  font-size: 14px;
  background-color: #DF0941;
  margin: 12px 24px ;
  padding: 12px 28px;
  border-radius: 3px;
}

.No-error-banner {
  margin: 0;
  padding: 0;
}

/* MOBILE STYLES */

.Mobile-header-bg {
  transition: all 300ms;
}

.Mobile-pl-icon {
  display: none;
}

.Mobile-menu-link {
  display: none;
}

#Mobile-page-title { 
  display: none;
}

@media (max-width: 1124px) {
  .Comment-modal {
    margin-top: 24px;
  }
}

@media (max-width: 650px) {

  input[type="text"],
  input[type="number"],
  input[type="password"],
  input[type="text"],
  input[type="email"],
  textarea,
  select:focus {
    font-size: 16px;
  }

  .App {
    background-image: none;
    background-color: #F2F5FC;
  }

  .Main {
    padding: 0;
  }

  .Content {
    display: flex;
    margin-top: 0;
  }

  .Container {
    width: 100%;
    max-width: 100%;
  }

  .Card {
    width: 100%;
    max-width: 100%;
    border-radius: 0;
    background-color: rgba(0, 0, 0, 0);
    box-shadow: none;
    padding-top: 0;
  }

  .Form-input {
    background-color: white;
  }

  .Mobile-header-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 0 24px;
    margin-bottom: 4px;
  }

  .Mobile-pl-icon {
    display: flex;
    flex-direction: row;
    align-content: center;
    width: 25%;
    text-align: left;
  }

  .Mobile-header-bg a {
    height: 25px;
  }

  .Page-title-wrapper {
    flex-grow: 2;
    width: 50%;
  }

  #Mobile-page-title { 
    display: block;
  }

  .Page-title {
    color: white;
  }

  .Mobile-menu-link {
    color: white;
    font-weight: 600;
    font-size: 14px;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    width: 25%;
  }

  .Mobile-menu-text {
    margin-right: 4px;
  }

  .Mobile-menu-icon {
    height: 10px;
    width: 12px;

  }

  .Mobile-header-bg {
    background-color: #0093FF;
    padding: 14px 0;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 100;
    box-shadow: 0 2px 8px 0 rgba(0,0,0,0.15);
  }

  /* Comments */
  .Modal-overlay {
    z-index: 10000;
  }

  .Comment-modal {
    height: 100%;
    width: 100%;
    margin: 0;
    justify-content: space-between;
  }

  .Task-title {
    font-size: 16px;
    padding: 8px 12px;
    max-height: 100%;
  }

  .Task-title > h1 {
    margin: 0;
  }

  .Phablet-task-list-bg {
    background-color: white;
    margin: 140px 16px 12px 16px;
    padding: 12px 0;
    border-radius: 3px;
    box-shadow: 0 2px 14px 0 rgba(0,0,0,0.05);
  }

  .Phablet-task-history-bg {
    margin: 122px 16px 12px 16px;
    padding: 0;
    border-radius: 3px;
  }

  .Phablet-rules-bg {
    background-color: white;
    margin: 78px 16px 12px 16px;
    padding: 12px 0;
    border-radius: 3px;
    box-shadow: 0 2px 14px 0 rgba(0,0,0,0.05);
  }

  .Profile {
    margin-top: 34px;
  }

  .Profile-title {
    display: none;
  }

  .Phablet-profile-bg {
    margin: 0px 8px;
    padding: 12px 0;
    border-radius: 3px;
  }

  .Delete-account {
    max-width: 90%;
    margin: auto;
    margin-bottom: 8px;
  }

  .Form-container {
    padding-top: 32px;
  }
}

@media (max-width: 400px) {
  .Phablet-task-list-bg {
    background-color: rgba(0,0,0,0);
    box-shadow: none;
    padding: 0 0 12px 0;
    margin-top: 118px;
  }
}