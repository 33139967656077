.Input-component {
  height: 48px;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  padding: 0 24px;
  margin-top: 12px;
  margin-bottom: 8px;
  transition: all 300ms;
  align-items: stretch;
}

.Task-input {
  background-color: #F2F5FC;
  font-size: 14px;
  border: 0px;
  padding-left: 16px;
  width: 100%;
  border-radius: 5px 0 0 5px;
}

.Task-input:focus {
  outline: 2px solid #0093FF;
}

.Task-btn:focus {
  outline: 0px;
}

.Task-btn {
  background-color: #E5E8EF;
  color: #9FB0BB;
  font-weight: 600;
  font-size: 14px;
  border: 0px;
  min-width: 144px;
  border-radius: 0 5px 5px 0;
  transition: 200ms; 
}

.Task-btn.Active:hover {
  background-color: #0084E5;
}

.Task-btn.Save {
  background-color: transparent;
  color: #0093FF;
  font-size: 14px;
  font-weight: 600;
  height: 46px;
  border: 2px solid #F2F5FC;
  padding: 0 16px;
  min-width: 94px;
  cursor: pointer;
}

.Task-btn.Save:hover {
  color: white;
  background-color: #0093FF;
  border: 2px solid #0093FF;
}

.Task-btn.Cancel {
  background-color: transparent;
  color: #9FB0BB;
  padding: 0 16px;
  min-width: 44px;
  cursor: pointer;
}

.Task-btn.Active {
  background-color: #0093FF;
  color: white;
  cursor: pointer;
}

.Empty-state-div {
  display: flex;
  text-align: left;
  padding: 0 24px;
}

.Empty-state-msg {
  color: #0093FF;
  font-weight: bold;
  letter-spacing: -.5px;
  font-size: 14px;
  
}

.Empty-state-icon {
  height: 14px;
  display: flex;
  margin: 0 8px;
}

.Empty-state-icon.Mobile {
  display: none;
}

@media (max-width: 650px) {
  .Task-btn {
    min-width: 98px;
  }
}


@media (max-width: 400px) {
  .Input-component {
    position: fixed;
    bottom: 0;
    right: 0;
    left: 0;
    box-shadow: 0 2px 14px 0 rgba(0,0,0,0.05);
    z-index: 100;
    padding: 8px 8px 12px 8px;
    background-color: #F2F5FC;
    margin-bottom: 0;
  }

  .Task-input {
    background-color: white;
  }

  .Task-btn {
    background-color: white;
    line-height: 16px;
    color: #0084E5;
    font-size: 13px;
  }

  .Task-btn.Save {
    font-size: 13px;
    padding: 0;
    min-width: 64px;
    margin-right: 4px;
  }

  .Task-btn.Cancel {
    padding: 0;
    min-width: 54px;
  }

  .Empty-state-icon {
    display: none;
  }

  .Empty-state-div {
    display: block;
    text-align: center;
  }

  .Empty-state-icon.Mobile {
    display: block;
    height: 44px;
    margin: auto;
    width: 100%;
    margin-top: 225px;
  }

  .Empty-state-msg {
    color: #9FB0BB;
    font-size: 18px;
    margin-top: 12px;
    display: block;
    width: 100%;
  }
}