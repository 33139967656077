.Progress-bar {
  padding: 0 24px;
  margin-bottom: 18px;
}

.Bar-bg {
  width: 100%;
  background-color: #F2F5FC;
  height: 14px;
  border-radius: 6px;
  margin-top: 12px;
}

.Progress {
  height: 100%;
  width: 0%;
  background-color: #0093FF;
  border-radius: 6px;
  transition: 300ms;
}

.Tasks-left-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.Tasks-complete-icon {
  color: #0093FF;
  font-size: 14px;
  opacity: 0;
  display: none;
  transition: 300ms;
}

.Tasks-complete-icon.Win {
  opacity: 1;
  display: flex;
  background-color: rgba(0,0,0,0);
}

.Tasks-left {
  font-weight: bold;
  font-size: 0.75em;
  color: #9FB0BB;
  margin: 8px 0 8px 4px;
  transition: 300ms;
}

.Tasks-left.Win {
  color: #636F77;
  background-color: rgba(0,0,0,0);
}


@media (max-width: 650px) {
  .Progress-bar {
    margin: 0;
  }
  .Bar-bg {
    display: none;
  }
  .Tasks-left {
    margin: 0;
  }

  .Tasks-left {
    color: #0066B2;
  }

  .Tasks-complete-icon.Win {
    color: rgba(255, 255, 255, 0.5);
    margin-right: 4px;
  }

  .Tasks-left.Win {
    color: white;
  }

}