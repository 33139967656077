.Nav-container {
  padding: 16px;
  position: fixed;
  width: 250px;
  transition: all 300ms;
  opacity: 1;
  left: 0px;
}

.Nav {
  width: 175px;
  padding: 8px 8px 0px 8px;
  text-align: left;
  list-style-type: none;
  display: flex;
  flex-direction: column;
  text-transform: uppercase;
  background-color: white;
  border-radius: 5px;
  margin: 0;
}

.Nav-link {
  padding: 12px 12px;
  text-decoration: none;
  color: #9FB0BB;
  font-weight: bold;
  margin-bottom: 8px;
  transition: 300ms;
  border-radius: 5px;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.Nav-link > li {
  margin-left: 8px;
}

.Nav-link > i {
  font-size: 18px;
  width: 24px;
}

.Nav-link:hover {
  color: #0093FF;
}

.active {
  color: #0093FF;
  background-color: #F2F5FC;
  border-radius: 3px;
  /* box-shadow: 0 2px 14px 0 rgba(0,0,0,0.05); */
}

.pl-nav-icon {
  width: 24px;
  height: 18px;
}

.Nav-link:hover .pl-icon-poly {
  fill: #0093FF;
}

.pl-icon-poly {
  fill: #9FB0BB;
  transition: 300ms;
}

.active .pl-icon-poly {
  fill: #0093FF;
}


@media (max-width: 1124px) {
  .Nav-container {
    left: -225px;
    visibility: hidden;
  }
}

@media (max-width: 650px) {
  .Nav-container {
    display: none;
  }
}